import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class SiteMaintenanceService  {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/site-maintenance';
    }

    first() {
        let url = this.#api;
        return apiService.get(url);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data)
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }
}
